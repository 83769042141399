<template>
  <bg-page>
    <div class="Box">
      <div class="limitBox">
        <div>
          <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
        </div>
        <div>
          <!--四项tabs-->
          <div class="TabsList">
            <p
              v-for="(item, index) in state.cateOptions"
              :key="index"
              :class="{ active: state.cate_id == item.value }"
              @click="ChangeTabsIndex(item)"
            >
              {{ item.label }}
            </p>
          </div>
          <div class="SearchBox">
            <div>
              <span class="iconfont iconsousuo"></span>
              <input
                placeholder="请输入关键词搜索"
                type="search"
                v-model="state.title"
              />
            </div>
            <div @click="Search">
              <span>搜索</span>
            </div>
          </div>
          <red-divider />
          <div class="areaPolicy">
            <NewsInfoComp
              :dataList="state.dataList"
            ></NewsInfoComp>
          </div>
        </div>
        <!--分页-->
        <Pagination
          :config="state.PageConfig"
          @handleCurrentPage="handleCurrentPage"
        ></Pagination>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import Pagination from "@/components/Pagination/Pagination";
import NewsInfoComp from "./Components/NewsInfoComp";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
import api from "@/comment/request/api";
export default {
  name: "News",
  components: { Pagination, NewsInfoComp, FirstTitle },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "政策文件",
        EnglishName: "Policy Document",
      },
      cateOptions: [],
      cate_id: 1,
      PageConfig: {
        currentPage: 1,
        total: 1,
        pageNum: 5,
      },
      dataList: [],
      title: "",
    });
    const ChangeTabsIndex = (item) => {
      state.cate_id = item.value;
      state.PageConfig.currentPage = 1;
      state.title = "";
      GetDataList();
    };
    // 分页
    const handleCurrentPage = (index) => {
      state.PageConfig.currentPage = index;
      GetDataList();
    };
    // 搜索
    const Search = () => {
      GetDataList();
    };
    // l列表
    const GetDataList = () => {
      const params = {
        page: state.PageConfig.currentPage,
        limit: state.PageConfig.pageNum,
        cate_id: state.cate_id,
        title: state.title,
      };
      api.GetNewsList(params).success((res) => {
        state.dataList = res.data.data.data;
        state.PageConfig.total = res.data.data.total;
      });
    };
    // 类别
    api.GetNewsCate().success((res) => {
      state.cateOptions = res.data.data;
      if (res.data.data.length) state.cate_id = res.data.data[0].value;
      GetDataList();
    })
    return {
      state,
      ChangeTabsIndex,
      handleCurrentPage,
      Search,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  .TabsList {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    p {
      cursor: pointer;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #666666;
      padding: 0 40px;
      border-right: 2px solid #851010;
    }
    p:last-child {
      border: none;
    }
    .active {
      color: #7f0404;
    }
  }
}
</style>